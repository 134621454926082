<template>
  <div class="order-page--fulfill">
    <v-row no-gutters>
      <v-col cols="12">
        <redirect
          v-if="data"
          class="mb-5"
          :title="data.name"
          :routeWithId="{
            name: 'website.orders.detail',
            params: {
              id: data.id,
            },
          }"
        />
        <div class="page-header">
          <h2 class="page-title">{{ 'Fulfillment' }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col v-if="data" cols="12" sm="8" class="d-flex flex-column">
        <div class="elevation-form pa-3">
          <div class="d-flex align-center mb-6">
            <h3>{{ 'Mark as fulfilled' }}</h3>
          </div>
          <span class="fz-12"> QUANTITY TO FULFILL </span>
          <div>
            <table class="table-fulfillment">
              <thead>
                <tr>
                  <th>Items</th>
                  <th></th>
                  <th>Weight</th>
                  <th>Quantity</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="form-component" v-for="(item, k) in itemsUnfulfilled" :key="item.item_id">
                  <td>
                    <div class="image-item">
                      <img v-if="!item.image" style="width:50px" :src="'@/assets/images/no-image.png'" alt="" />
                      <img style="width: 100%" v-else :src="imageHelpers.url(item.image)" />
                    </div>
                  </td>
                  <td>
                    <div class="ml-3 py-3">
                      <router-link
                        v-if="item.sku !== 'tip'"
                        :to="{ name: 'website.products.update', params: { id: item.product_id } }"
                      >
                        {{ item._title }}
                      </router-link>
                      <span v-else>
                        {{ item._title }}
                      </span>
                      <div>{{ item._variant_variantTitle }}</div>
                      <div v-if="personalizeText(item)">
                        <div v-for="(personalize, index) in personalizeText(item)" :key="index">
                          {{ personalize.name ? personalize.name : 'Name' }}: {{ personalize.text }}
                        </div>
                      </div>
                      <div>SKU:{{ item._sku }}</div>
                    </div>
                  </td>
                  <td>
                    <div class="ml-3">
                      {{ item.weight }}
                      {{ item.weightUnit }}
                    </div>
                  </td>
                  <td>
                    <div class="ml-3">
                      <v-text-field
                        :max="item.qty"
                        v-model.number="formData.lineItems[k].quantity"
                        type="number"
                        @keyup="checkQYT(k, item.qty)"
                        :rules="maxItemRefundRules"
                        :suffix="`of ${item.qty}`"
                      ></v-text-field>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="elevation-form pa-3 mt-3">
          <div class="d-flex align-center mr-2">
            <h3>{{ 'Tracking Information' }}</h3>
            <span>(OPTIONAL)</span>
          </div>
          <v-row>
            <v-col cols="7">
              <div>
                <span>Tracking number </span>
                <v-text-field v-model="formData.trackingNumber" hide-details=""></v-text-field>
              </div>
            </v-col>
            <v-col cols="5">
              <div>
                <span>Shipping carrier </span>
                <select name="Carrier" class="form-control" id="Carrier" v-model="formData.trackingCompany">
                  <option v-for="(carrier, index) in shippingCarrier" :key="index" :value="carrier.value">
                    {{ carrier.name }}
                  </option>
                </select>
              </div>
            </v-col>
            <v-col cols="12" v-if="formData.trackingNumber">
              <div>
                <span>Tracking URL </span>
                <v-text-field v-model="formData.trackingUrl" hide-details=""></v-text-field>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <div>NOTIFY CUSTOMER OF SHIPMENT</div>
          <v-checkbox v-model="formData.notifyCustomer" label="Send shipment details to your customer now"></v-checkbox>
        </div>
      </v-col>
      <v-col v-if="data.customer" cols="12" sm="4" class="d-flex flex-column">
        <!-- <div class="elevation-form pa-3">
          <ProductAvailability />
        </div> -->
        <div class="elevation-form pa-3">
          <div class="d-flex justify-space-between">
            <h4>Shipping address</h4>
          </div>
          <div class="link-add">{{ data.customer.full_name }}</div>
          <div class="link-add">{{ data.customer.address1 }}</div>
          <div class="link-add">{{ data.customer.address2 }}</div>
          <div class="link-add">{{ data.customer.city }}</div>
          <div class="link-add">{{ data.customer.country_code }}</div>
          <div>
            The customer selected <strong>{{ data.paidInfo.shipping_title }}</strong> ({{
              currency.priceFormat(data.paidInfo.shipping_total)
            }}) at checkout.
          </div>
        </div>
        <div class="elevation-form pa-3 mt-3 form-component">
          <div class="">
            <h4>Summary</h4>
          </div>
          <div class="d-flex">
            <span class="badge-info mr-3"> {{ countItem }} of {{ totalItems }}</span>
            <div>
              MARK AS FULFILLED
              <div>None #</div>
            </div>
          </div>
          <!-- <div class="d-flex justify-space-between">
            <span class="edit-label">Edit</span>
            No notes from customer
          </div> -->
        </div>
        <v-divider></v-divider>
        <div class="elevation-form pa-3 py-5">
          <div class="d-flex justify-center">
            <v-btn
              :disabled="countItem == 0 || isLoadingAction"
              :loading="isLoadingAction"
              style="width: 100%"
              color="primary"
              @click="submitFulfillment"
            >
              Fulfill Items
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <div style="width: 100%; height: 100%" v-if="loading" class="d-flex justify-center align-center">
      <page-loader />
    </div> -->
    <delete-action :content="'product ' + name" :title="'Delete ' + name" />
    <BeforeLeavePage />
    <EditCustomer @reloadOrder="getData" v-if="data" ref="modal-edit-customer" />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import { orderApi } from '@/apis/order';
import { imageFake } from '@/const/image';
import imageHelpers from '@/helpers/image';
import STORAGE_NAME from '@/const/storage';
import datetime from '@/helpers/datetime';
import EditCustomer from './components/EditCustomer';
import currency from '@/helpers/currency';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    EditCustomer,
  },
  data() {
    return {
      stores: null,
      shippingCarrier: [
        {
          name: 'DHL',
          value: 'DHL_GLOBAL_MAIL',
        },
        {
          name: '4PX',
          value: 'FOUR_PX_EXPRESS',
        },
        {
          name: 'AU Post',
          value: 'AUSTRALIA_POST',
        },
        {
          name: 'USPS',
          value: 'USPS',
        },
        {
          name: 'Fedex',
          value: 'FEDEX',
        },
        {
          name: 'EMS',
          value: 'CHINA_POST',
        },
        {
          name: 'CA Post',
          value: 'CANADA_POST',
        },
        {
          name: 'UPS',
          value: 'UPS',
        },
        {
          name: 'OnTrac',
          value: 'US_ONTRAC',
        },
        {
          name: 'DHL eCommerce US',
          value: 'DHL_GLOBAL_MAIL',
        },
        {
          name: 'DHL Global eCommerce',
          value: 'DHL_GLOBAL_ECOMMERCE',
        },
        {
          name: 'Yanwen',
          value: 'YANWEN_CN',
        },
        {
          name: 'China Post',
          value: 'CHINA_POST',
        },
        {
          name: 'Malaysia Post EMS',
          value: 'MALAYSIA_POST',
        },
        {
          name: 'Epacket',
          value: 'EMS_CN',
        },
        {
          name: 'EMS China',
          value: 'EMS_CN',
        },
        {
          name: 'HONGKONG POST',
          value: 'HK_POST',
        },
        {
          name: 'SF EXPRESS',
          value: 'SF_EXPRESS',
        },
        {
          name: 'Singapore Post',
          value: 'SINGPOST',
        },
        {
          name: 'Russia Post',
          value: 'RUSSIAN_POST',
        },
        {
          name: 'Poste Italiane',
          value: 'POSTE_ITALIANE',
        },
        {
          name: 'LA Poste',
          value: 'LAPOSTE',
        },
        {
          name: 'Other',
          value: 'OTHER',
        },
      ],
      timeline: [],
      datetime,
      events: [],
      input: null,
      nonce: 0,
      imageFake,
      imagesSelect: [],
      isVariantDefault: false,
      showDialogDelete: false,
      name: '',
      idItem: null,
      isLoading: false,
      loading: false,
      page: {},
      showHeader: false,
      searchString: '',
      listVariants: [],
      // Product options
      options: [], // need
      imageHelpers,
      data: null,
      totalPrice: 0,
      formData: {
        notifyCustomer: true,
        shipmentStatus: 'done',
        trackingCompany: null,
        trackingNumber: null,
        trackingUrls: [],
        trackingUrl: null,
        lineItems: null, // null = fulfill all else {id,quantity}
        status: '',
      },
      itemsUnfulfilled: [],
      isLoadingAction: false,
      maxItemRefundRules: [],
      currency,
    };
  },
  async created() {
    this.getData();
  },
  methods: {
    checkQYT(k, qty) {
      this.maxItemRefundRules = [v => v <= qty || `Number refund item should be less than ${qty}`];
      if (this.formData.lineItems[k].quantity < 0 || this.formData.lineItems[k].quantity === '') {
        this.formData.lineItems[k].quantity = 0;
      } else if (this.formData.lineItems[k].quantity > qty) {
        this.formData.lineItems[k].quantity = qty;
      }
    },
    async submitFulfillment() {
      this.isLoadingAction = true;
      try {
        let convertData = { ...this.formData };
        convertData.lineItems = this.formData.lineItems.filter(item => {
          return item.quantity > 0;
        });
        let res = await orderApi.createFulfillment(this.idItem, convertData);
        this.isLoadingAction = false;
        this.$router.push({ name: 'website.orders.detail', params: { id: this.data.id } });
        console.log(res);
      } catch (error) {
        this.isLoadingAction = false;
      }
      console.log();
    },
    async getData() {
      try {
        let vm = this;
        this.loading = true;
        this.idItem = this.$route.params.id;
        let storeDomain = localStorage.getItem(STORAGE_NAME.STORE_DOMAIN);
        this.data = (await orderApi.getById(this.idItem)).data;
        let fulfillmentResponse = await orderApi.getFulfillmentByIdOrder(this.idItem);

        let itemsFulfilled = [];
        fulfillmentResponse.data.fulfillments.forEach(element => {
          element.lineItems.forEach(el => {
            if (itemsFulfilled.find(i => i.id === el.id)) {
              let index = itemsFulfilled.findIndex(i => i.id === el.id);
              if (index != -1) {
                vm.$set(itemsFulfilled[0], 'quantity', itemsFulfilled.find(i => i.id === el.id).quantity + el.quantity);
              }
            } else {
              itemsFulfilled.push(el);
            }
          });
        });

        this.data.items.forEach(item => {
          let findItem = itemsFulfilled.find(i => i.id === item.item_id);
          let obj = {};
          obj.item_id = item.item_id;
          obj.image = item.image;
          obj.title = item.title;
          obj.price = item.price;
          obj.weight = item.weight;
          obj.weightUnit = item.weightUnit;
          obj.sku = item.sku;
          obj._vendor = item._vendor;
          obj.attributes = item.attributes;
          obj.product_id = item.product_id;
          obj._title = item._title;
          obj._variant_variantTitle = item._variant_variantTitle;
          obj._sku = item._sku;
          if (findItem) {
            if (parseInt(findItem.quantity) < parseInt(item.qty)) {
              obj.qty = parseInt(item.qty) - parseInt(findItem.quantity);
              this.itemsUnfulfilled.push(obj);
            }
          } else {
            obj.qty = parseInt(item.qty);
            this.itemsUnfulfilled.push(obj);
          }
        });

        this.formData.lineItems = this.itemsUnfulfilled.map(item => {
          return {
            id: item.item_id,
            quantity: item.qty,
            max_quantity: item.qty,
          };
        });

        this.data.name = `#${storeDomain.toUpperCase().split('.')[0]}-${this.data.orderId}`;
        // this.data.items.forEach(element => {
        //   this.totalPrice = this.totalPrice + element.price;
        // });
        this.timeline = this.data.transactions;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error, 'error');
      }
      this.loading = false;
    },
    isPersonalizeProduct(item) {
      return Array.isArray(item?.personalizeText) && item?.personalizeText?.length > 0;
    },
    getPersonalizeTxt(item) {
      return item.personalizeText.join(',');
    },
    personalizeText(item) {
      let data = [];
      let findItem = this.data.paidInfo.items.find(i => i.item_id == item.item_id);
      data = findItem ? findItem.personalizeText : [];
      return data;
    },
  },
  computed: {
    totalItems() {
      let total = this.formData.lineItems.reduce((total, item) => {
        return (total += item.max_quantity);
      }, 0);
      return total;
    },
    countItem() {
      let count = this.formData.lineItems.reduce((count, item) => {
        return (count += item.quantity || 0);
      }, 0);
      return count;
    },
  },
  watch: {
    attributes: {
      handler: function(val, oldVal) {
        this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
        // if (this.showHeader) {
        //   this.$v.$reset();
        // }
      },
      deep: true,
    },
  },

  validations: {
    attributes: {
      title: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
<style lang="scss">
.order-page--fulfill {
  .btn-link {
    color: var(--v-primary-base);
    &:hover {
      text-decoration: none;
    }
  }
  .badge-info {
    display: flex;
    height: auto;
    min-width: 16px;
    padding: 0px 8px;
    font-size: 1em;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    vertical-align: middle;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    background-color: #e4e5f0;
    border-radius: 10px;
  }
  .edit-label {
    font-weight: 700;
    color: var(--v-primary-base);
    cursor: pointer;
  }
  .image-item {
    width: 50px;
    height: 50px;
    border: 0.2px solid #dddd;
    margin-right: 10px;
  }
  .title-info-customer {
    font-weight: 700;
    line-height: 1rem;
    text-transform: uppercase;
    font-size: 12px;
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .table-fulfillment {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #000;
        th {
          font-size: 14px;
          color: #525c64;
          font-weight: 500;
          text-align: left;
          padding: 8px 10px;
          border-bottom: 1px solid #e4e5f0;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }
  }
}
</style>
